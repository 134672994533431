@import '~smu-global-styles/globals';

.suite-questionnaire-results-layout {
  display: flex;
  height: 100%;
  padding: 20px;
  position: relative;
  width: 100%;

  &__content {
    position: relative;
    width: calc(100% - 400px);

    @media screen and (min-width: $desktop-large) and (max-width: 1660px) {
      width: calc(100% - 300px);
    }
  }

  &__loader {
    align-items: center;
    display: flex;
    flex: 1;
    justify-content: center;
    position: absolute;
    left: 49%;
    bottom: 10%;

    &--center {
      left: 50%;
      bottom: 50%;
    }

    &-exporting {
      align-items: center;
      background-color: $app-background;
      display: flex;
      height: 100vh;
      left: 0;
      position: absolute;
      top: 0;
      width: 100%;
      z-index: 3;
    }
  }

  &__results {
    height: 100%;
  }

  &--webview {
    padding: 0;
  }

  &--exporting {
    overflow: hidden;

    & > *:last-child {
      overflow: hidden;
    }
  }  

  &--error {
    & > *:last-child {
      flex: 1;
      min-height: auto;
    }
  }

  &--with-content {
    height: auto;
  }

  @include screenMobile {
    padding-bottom: 0;
    justify-content: center;

    &__content {
      position: relative;
      width: 100%;
    }

    & > *:last-child {
      padding: 0 10px;
    }
  }
}
