@mixin app-metric-empty-text() {
  color: getThemeColor('gray.550');
  font-size: $font-12;
  font-family: $font-text;
  position: relative;
}

@mixin app-metric-empty() {
  display: flex;
  flex-direction: column;
  justify-content: center;
  min-height: 159px;
}

@mixin app-metric-empty-img() {
  display: flex;
  justify-content: center;
  flex-direction: column;
  background-position: center;
  background-repeat: no-repeat;
  height: 53px;
  margin-bottom: 26px;
  width: 100%;
}

@mixin app-metric-empty-icon() {
  align-items: center;
  color: #14145a;
  display: flex;
  height: 100%;
  justify-content: center;
  width: 100%;
}
