@import "~smu-global-styles/globals";

.suite-questionnaire-results-header {

  &__text {
    border-bottom: 1px solid $app-card-border;
    color: $section-title;
    font-family: $font-header;
    font-size: $font-12;
    font-weight: bold;
    padding: 20px 15px 17px 15px;
    position: relative;

    &-subtitle {
      color: $text-primary;
      font-family: $font-rubik;
      font-size: $font-18;
      text-transform: lowercase;

      &::first-letter {
        text-transform: uppercase;
      }
    }

    &-state {
      display: flex;
      align-items: center;
    }

    &-label {
      margin-left: 16px;
    }
  }

  &__details {
    padding: 30px 15px 20px 15px;

    &-title {
      font-family: $font-rubik;
      font-size: $font-16;
      font-weight: bold;
      margin-bottom: 15px;
    }

    &-values {
      align-items: center;
      display: flex;
      flex-wrap: wrap;
    }
  }

  &__container-select {
    align-items: center;
    display: flex;
    margin-top: 30px;
  }

  &__select {
    width: 24%;
    margin-right: 5px;
  }

  &__options {
    display: flex;
    align-items: baseline;
  }

  &__option {
    margin: 0 25px;

    &--no-margin {
      margin-right: 0;
    }

    &:first-child {
      margin-left: 0;
    }
  }

  &__export {
    margin: 0 0 0 auto;
    width: 140px;

    & > *:first-child {
      width: calc(100% - 38px);
    }
  }

  &__footer {
    align-items: center;
    display: flex;
    flex-wrap: wrap;
  }

  &__graphic-container {
    position: relative;
    width: 75%;
  }

  &__graphic-title {
    color: $text-secondary;
    display: flex;
    font-family: $font-rubik;
    font-size: $font-12;
    margin-bottom: 10px;
    position: relative;
    top: 20px;
    z-index: 1;
  }

  &__chart {
    padding: 0;

    .suite-questionnaire-results-graphics__body {
      margin: 0;
    }

    &--exporting {
      width: 100%;
    }
  }

  &__view {
    align-items: center;
    display: flex;
    position: absolute;
    right: 15px;
    top: 15px;

    &-value {
      border-radius: 3px;
      color: $text-primary;
      cursor: pointer;
      font-family: $font-open;
      font-size: $font-14;
      font-weight: normal;
      margin: 0 4px;
      padding: 13px;

      &--active {
        border: 1px solid $app-card-border;
      }
    }

    &-info {
      cursor: pointer;
      height: 100%;
    }
  }

  @include screenMobile {
    &.suite-questionnaire-results-header--mobile {
      .suite-questionnaire-results-header {
        &__option {
          margin: 10px 25px 10px 0;
        }

        &__export {
          width: 100%;
        }

        &__chart {
          width: 100%;
        }
      }
    }
    &__graphic-container {
      width: 100%;
    }

    &__text {
      display: flex;
      flex-direction: column;
    }

    &__view {
      margin-top: 10px;
      position: relative;
      right: 0;
      top: 0;
    }

    &__select {
      width: 100%;
    }
  }
}
