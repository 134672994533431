@mixin configureTheme($customTheme: ()) {
  $theme: non-destructive-map-merge($theme-default, $customTheme) !global;
}

@mixin useThemeColors($prefix: "color") {
  @each $key, $value in getThemePalette() {
    &--#{$prefix}-#{$key} {
      $theme-map-colors: $value !global;
      @content;
      $theme-map-colors: () !global;
    }
  }
}
